import axios from "@/utils/request";

// app推广信息
export function queryAppPromoteInfo() {
    return axios.post(`/userinvite/info`);
}

// 游戏推广信息
export function queryGamePromoteInfo() {
    return axios.post(`/userinvite/info/wali`);
}

// 查询邀请列表
export function queryUserInviteList(data) {
    return axios.post(`/userinvite/userlist`, data);
}

// 查询app推广收益
export function queryAppInvite(data) {
    return axios.post(`/userinvite/incomelist`, data);
}

// 查询Game推广收益
export function queryGameInvite(data) {
    return axios.post(`/userinvite/incomelist/wali`, data);
}

