<template>
    <div class="promotionRecord">
        <div class="navbar">
            <div @click="$router.go(-1)">
                <svg-icon iconClass="leftArrow" class="leftArrow"></svg-icon>
            </div>
            <div>推广记录</div>
            <div class="rightItem"></div>
        </div>
        <div class="bodyBox">
            <div class="inviteTotal">累计邀请用户{{total}}人</div>
            <div class="splitLine"></div>
            <div class="listBox">
                <Pullrefresh @onLoad="onLoad" :loading="loading" :finished="finished" @onRefresh="onRefresh"
                    :refreshing="refreshing" :firstLoading="firstLoading" :isNoData="isNoData" :isHigehtMax="true">
                    <div class="inviteInfo" v-for="(item, index) in userInviteList" :key="index">
                        <div class="name">{{item.name}}</div>
                        <div class="date">{{item.createAt | time}}</div>
                    </div>
                </Pullrefresh>
            </div>
        </div>
    </div>
</template>

<script>
    import {  queryUserInviteList } from '@/api/mine/promote.js';
    import Pullrefresh from "@/views/widget/PullRefresh.vue";
    export default {
        components: {
            Pullrefresh,
        },
        data() {
            return {
                loading: false,
                finished: false,
                refreshing: false,
                isNoData: false,
                firstLoading: true, //是否第一次加载
                pageNum: 1,
                pageSize: 10,
                total: 0,
                userInviteList: []
            }
        },
        created() {
            this.getData();
        },
        methods: {
            //上滑加载
            onLoad() {
                this.pageNum++;
                this.getData();
            },
            //刷新
            onRefresh() {
                this.refreshing = true;
                this.finished = false;
                this.pageNum = 1;
                this.userInviteList = [];
                this.getData();
            },
            //获取数据
            async getData() {
                this.loading = true;
                let req = {
                    pageNumber: this.pageNum,
                    pageSize: this.pageSize,
                }
                let ret = await this.$Api(queryUserInviteList, req);

                this.loading = false;
                this.refreshing = false;
                this.firstLoading = false;

                if (ret && ret.code == 200) {
                    let list = ret.data.list ? ret.data.list : [];
                    this.total = ret.data.total;
                    this.userInviteList = this.userInviteList.concat(list);

                    if (list.length == 0 && this.pageNum == 1) {
                        this.isNoData = true;
                        return;
                    }
                    if (list.length < this.pageSize) {
                        this.finished = true;
                    }
                }
            },
        }
    }
</script>

<style lang='scss' scoped>
    .promotionRecord {
        .navbar {
            padding: 0 16px;
            height: 44px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            background: $vermillion;
            color: $white1;
            font-size: 20px;

            .rightItem {
                width: 18px;
            }
        }

        .bodyBox {
            padding: 0 10px;
            .listBox {
                height: calc(100vh - 102px);
            }

            .inviteTotal {
                height: 58px;
                line-height: 58px;
                font-size: 16px;
            }

            .splitLine {
                height: 2px;
                background-image: linear-gradient(90deg, $white1 0%, $whiteFour 50%, $white1 100%);
                margin-bottom: 15px;
            }

            .inviteInfo {
                border-width: 0 0 1px 0;
                border-style: solid;
                border-color: $whiteSeven;

                .name {
                    font-size: 13px;
                    margin-top: 10px;
                }

                .date {
                    color: $warmGreyTwo;
                    font-size: 12px;
                    margin: 4px 0 5px;
                }
            }
        }


    }
</style>